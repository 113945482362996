import { Outlet, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//

import { Suspense, createElement, lazy, useCallback, useEffect, useState } from 'react';
import { AuthGuard } from 'src/auth/guard';
import { useAuthContext } from 'src/auth/hooks';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard';
import { useLocales } from 'src/locales';
// import { authDemoRoutes } from './auth-demo';
import { SYSTEM_ADMIN } from 'src/auth/context/jwt/auth-provider';
import { HomePage, mainRoutes } from './main';


const NotFoundPage = lazy(() => import('src/pages/404'));
const TestPage = lazy(() => import('src/sections/masters/settings/test-page/test-page'));
const TableConfigurationListView = lazy(() => import('src/sections/masters/settings/table-configuration/table-configuration-view'));
const DynamicFormListView = lazy(() => import('src/sections/masters/settings/dynamic-form/dynamic-form-view'));
const FormSetupListView = lazy(() => import('src/sections/masters/settings/form-setup/form-setup-view'));
const FormBlockSetupListView = lazy(() => import('src/sections/masters/settings/form-block-setup/form-block-setup-view'));
const TableCreationListView = lazy(() => import('src/sections/masters/settings/table-creation/table-creation-view'));
const PageFormSetupListView = lazy(() => import('src/sections/masters/settings/page-form-setup/page-form-setup-view'));
const DynamicFormNewPage = lazy(() => import('src/sections/masters/settings/dynamic-form/dynamic-form-new-page'));
const RolesDynamicFormListView = lazy(() => import('src/sections/masters/settings/roles/roles.view'));
// ----------------------------------------------------------------------
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const TabWiseListView = lazy(() => import('src/sections/masters/settings/tab-wise-view/view/tab-wise-list-view'));
const SteppersForm = lazy(() => import('src/sections/masters/settings/dynamic-form/steppers-forms/steppers-forms'));
const StudyTemplate = lazy(() => import('src/sections/masters/settings/study-template/study-template'));
const TableConfigurationFormNewPage = lazy(() => import('src/sections/masters/settings/table-configuration/table-configuration-form-new-page'));
const ClientForm = lazy(() => import('src/sections/masters/client/client-form'));
const ActivityLogView = lazy(() => import('src/sections/masters/settings/activity-log/activity-log-view'));
const StudyBuilder = lazy(() => import('src/sections/masters/settings/study-builder/study-builder'));
const EnrollmentSubjectDynamicFormListView = lazy(() => import('src/sections/masters/settings/dynamic-form/dynamic-form-view-enrollment'));
const ReportListView = lazy(() => import('src/sections/masters/settings/report/report-view'));

export const Components: any = {
  DASHBOARD: <IndexPage />,
  DYNAMICFORMLISTVIEW: <DynamicFormListView />,
  TABLECONFIGURATIONLISTVIEW: <TableConfigurationListView />,
  FORMSETUP: <FormSetupListView />,
  FORMBLOCKSETUP: <FormBlockSetupListView />,
  PAGEFORMSETUPLISTVIEW: <PageFormSetupListView />,
  DYNAMICPAGE: <DynamicFormListView />,
  DYNAMICNEWPAGE: <DynamicFormNewPage />,
  ROLESPAGE: <DynamicFormNewPage />,
  TABLECREATIONLISTVIEW: <TableCreationListView />,
  ROLESDYNAMICFORMLISTVIEW: <RolesDynamicFormListView />,
  REGISTERPAGE: <DynamicFormListView />,
  STUDYTEMPLATE: <StudyTemplate />,
  MANAGEUSERPAGE: <TabWiseListView />,
  STEPPERSFORMS: <SteppersForm />,
  TABLECONFIGURATIONFORMNEWPAGE: <TableConfigurationFormNewPage />,
  CLIENTFORM: <ClientForm />,
  ENROLLMENTSUBJECTFORM: <EnrollmentSubjectDynamicFormListView />,
  ACTIVITYLOGVIEW: <ActivityLogView />,
  REPORTPAGE: <ReportListView />
};

export const CreateDynamicComponent = (elementName: any) => {
  // component does exist
  const { componentName, id, title } = elementName
  if (typeof Components[componentName] !== "undefined") {
    return Components[componentName];
  }
  // component doesn't exist yet
  return createElement(
    () => <div>The component <b>{title}</b> has not been created yet.</div>,
    { key: id }
  );
}

export default function Router() {
  const { t } = useLocales();
  const { user } = useAuthContext();
  const [menuItem, setMenuItem] = useState([] as any[]);
  const [authRouteData, setAuthRouteData] = useState([] as any[]);
  const [addEditMenuItem, setAddEditMenuItem] = useState([] as any[]);
  let rootPath = '';
  if (sessionStorage.getItem(SYSTEM_ADMIN) === "study") {
    rootPath = `study/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}/${user?.studyName?.replace(/ /g, '')?.toLowerCase()}` // pathName.split("/")[1]
  } else if (sessionStorage.getItem(SYSTEM_ADMIN) === "client") {
    rootPath = `client/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}` // pathName.split("/")[1]
  } else if (sessionStorage.getItem(SYSTEM_ADMIN) === "clientView") {
    rootPath = `sclinedc/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}` // pathName.split("/")[1]
  } 
  else {
    rootPath = 'sclinedc'
  }

  const addMenuChildren = useCallback((dt: any[], dtchild: any[]) => {
    dtchild.forEach((dr: any) => {
      const subchild2 = dt.filter(p => p.parentId === dr.id)
      const subchild: any[] = [];
      subchild2.forEach(x => {
        const item: any = {} as any
        item.title = t(x.title);
        const path: any = `${x.path}${x.path.indexOf(':id') === -1 ? "" : x.path.replace(":id", x.id)}`;
        item.path = `/${rootPath}/${path}`;
        item.id = x.id;
        item.addEditUrl = x.addEditUrl;
        item.componentName = x.componentName;
        if (item.addEditUrl) {
          if (item.componentName === 'ENROLLMENTSUBJECTFORM') 
            addEditMenuItem.push({ element: <SteppersForm />, path: `${item.addEditUrl}/enroll` })
          else  if (item.title === 'client') {
            addEditMenuItem.push({ element: <ClientForm />, path: x.addEditUrl })
          } else if (item.componentName === 'TABLECONFIGURATIONLISTVIEW')
            addEditMenuItem.push({ element: <TableConfigurationFormNewPage />, path: item.addEditUrl })
          else
            addEditMenuItem.push({ element: <DynamicFormNewPage />, path: item.addEditUrl })
        }
        if (x.componentName !== '') {
          item.element = CreateDynamicComponent(x);
        }
        if (x.icon !== '') {
          if (x.icon !== '') {
            item.icon = x.icon;
          }
        }
        if (item.addEditUrl) {
          if (item.componentName === 'ENROLLMENTSUBJECTFORM') {
            const routePath = {
              path: `/${rootPath}/${x.path}`,
              children: [
                { element: item.element, index: true },
                { path: ':viewId', element: <DynamicFormNewPage /> },
                { path: ':viewId/enroll', element: <SteppersForm /> },
              ],
            }
            subchild.push({ ...routePath });
          }
         else  if (item.title === 'Clients') {
            const routePath = {
              path: `/${rootPath}/${x.path}`,
              children: [
                { element: item.element, index: true },
                { path: ':viewId', element: <ClientForm /> },
              ],
            }
            subchild.push({ ...routePath });
          } else if (item.componentName === 'TABLECONFIGURATIONLISTVIEW') {
            const routePath = {
              path: `/${rootPath}/${x.path}`,
              children: [
                { element: item.element, index: true },
                { path: ':viewId', element: <TableConfigurationFormNewPage /> },
              ],
            }
            subchild.push({ ...routePath });
          } else {
            const routePath = {
              path: `/${rootPath}/${x.path}`,
              children: [
                { element: item.element, index: true },
                { path: ':viewId', element: <DynamicFormNewPage /> },
              ],
            }
            subchild.push({ ...routePath });
          }
        } else {
          subchild.push({ ...item });
        }
      })
      dr.title = t(dr.title);
      if (subchild != null && subchild.length > 0) {
        dr.children = subchild;
        const dtsubchild: any[] = subchild;
        addMenuChildren(dt, dtsubchild);
      }
    })
    return dtchild;
  }, [t])

  const getMenuStructure = useCallback((dt: any[]) => {
    let parent: any[] = [];
    const dtparent: any[] = [];
    if (dt != null && dt.length > 0) {
      parent = dt.filter(p => p.parentId === 0)

      if (parent != null && parent.length > 0) {
        parent.forEach(x => {
          const item: any = {} as any
          item.title = t(x.title);
          item.path = `/${rootPath}/${x.path}`;
          item.id = x.id;
          item.addEditUrl = x.addEditUrl;
          item.componentName = x.componentName;
          if (item.addEditUrl) {
            if (item.componentName === 'ENROLLMENTSUBJECTFORM') 
            addEditMenuItem.push({ element: <SteppersForm />, path: `${item.addEditUrl}/enroll` })
           else if (item.title === 'client') {
              addEditMenuItem.push({ element: <ClientForm />, path: x.addEditUrl })
            } else if (item.componentName === 'TABLECONFIGURATIONLISTVIEW')
              addEditMenuItem.push({ element: <TableConfigurationFormNewPage />, path: item.addEditUrl })
            else
              addEditMenuItem.push({ element: <DynamicFormNewPage />, path: item.addEditUrl })
          }
          if (x.componentName !== '') {
            item.element = CreateDynamicComponent(x);
          }
          if (x.icon !== '' && x.icon !== null) {
            if (x.icon !== '') {
              item.icon = x.icon;
            }
          }
          if (item.addEditUrl) {
            if (item.componentName === 'ENROLLMENTSUBJECTFORM') {
              const routePath = {
                path: `/${rootPath}/${x.path}`,
                children: [
                  { element: item.element, index: true },
                  { path: ':viewId', element: <DynamicFormNewPage /> },
                  { path: ':viewId/enroll', element: <SteppersForm /> },
                ],
              }
              dtparent.push({ ...routePath });
            } else  if (item.title === 'Clients') {
              const routePath = {
                path: `/${rootPath}/${x.path}`,
                children: [
                  { element: item.element, index: true },
                  { path: ':viewId', element: <ClientForm /> },
                ],
              }
              dtparent.push({ ...routePath });
            } else if (item.componentName === 'TABLECONFIGURATIONLISTVIEW') {
              const routePath = {
                path: `/${rootPath}/${x.path}`,
                children: [
                  { element: item.element, index: true },
                  { path: ':viewId', element: <TableConfigurationFormNewPage /> },
                ],
              }
              dtparent.push({ ...routePath });
            } else {
              const routePath = {
                path: `/${rootPath}/${x.path}`,
                children: [
                  { element: item.element, index: true },
                  { path: ':viewId', element: <DynamicFormNewPage /> },
                ],
              }
              dtparent.push({ ...routePath });
            }
          } else {
            dtparent.push({ ...item });
          }
        })

        dtparent.forEach((dr: any) => {
          const child1 = dt.filter(p => p.parentId === dr.id);
          const child: any[] = [];
          child1.forEach(x => {
            const item: any = {} as any
            item.title = t(x.title);
            item.path = `/${rootPath}/${x.path}`;
            item.id = x.id;
            item.addEditUrl = x.addEditUrl;
            item.componentName = x.componentName;
            if (item.addEditUrl) {
              if (item.componentName === 'ENROLLMENTSUBJECTFORM') 
                addEditMenuItem.push({ element: <SteppersForm />, path: item.addEditUrl })
              if (item.title === 'Clients') {
                addEditMenuItem.push({ element: <ClientForm />, path: x.addEditUrl })
              } else if (item.componentName === 'TABLECONFIGURATIONLISTVIEW')
                addEditMenuItem.push({ element: <TableConfigurationFormNewPage />, path: x.addEditUrl })
              else
                addEditMenuItem.push({ element: <DynamicFormNewPage />, path: x.addEditUrl })
            }
            if (x.componentName !== '') {
              item.element = CreateDynamicComponent(x);
            }
            if (x.icon !== '') {
              item.icon = x.icon;
            }
            if (item.addEditUrl) {
              if (item.componentName === 'ENROLLMENTSUBJECTFORM') {
                const routePath = {
                  path: `/${rootPath}/${x.path}`,
                  children: [
                    { element: item.element, index: true },
                    { path: ':viewId', element: <DynamicFormNewPage /> },
                    { path: ':viewId/enroll', element: <SteppersForm /> },
                  ],
                }
                dtparent.push({ ...routePath });
              } else  if (item.title === 'Clients') {
                const routePath = {
                  path: `/${rootPath}/${x.path}`,
                  children: [
                    { element: item.element, index: true },
                    { path: ':viewId', element: <ClientForm /> },
                  ],
                }
                child.push({ ...routePath });
              } else if (item.componentName === 'TABLECONFIGURATIONLISTVIEW') {
                const routePath = {
                  path: `/${rootPath}/${x.path}`,
                  children: [
                    { element: item.element, index: true },
                    { path: ':viewId', element: <TableConfigurationFormNewPage /> },
                  ],
                }
                child.push({ ...routePath });
              } else {
                const routePath = {
                  path: `/${rootPath}/${x.path}`,
                  children: [
                    { element: item.element, index: true },
                    { path: ':viewId', element: <DynamicFormNewPage /> },
                  ],
                }
                child.push({ ...routePath });
              }
            } else {
              child.push({ ...item });
            }

          })
          if (child != null && child.length > 0) {
            dr.children = child;
            const dtchild: any[] = child;
            addMenuChildren(dt, dtchild);
          }
        })
      }
    }
    return dtparent;
  }, [addMenuChildren, t])


  useEffect(() => {
    const menutItems: any[] = user?.menuItems || [];
    const menuItemsLevel0: any[] = menutItems.filter(x => x.level === 0);
    const menuItemsLevel1: any[] = menutItems.filter(x => x.level === 1);
    const authData: any[] = menutItems.filter(x => x.level === -1);
    const dataLevel0: any[] = getMenuStructure(menuItemsLevel0)
    const data: any[] = getMenuStructure(menuItemsLevel1)
    const authDataRoutes: any[] = getMenuStructure(authData)
  
    setMenuItem([{
      path: `/${rootPath}`,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ), children: [{ element: <IndexPage />, index: true }, ...dataLevel0, ...data, 
        { path: `/${rootPath}/study/:id/:viewId/enroll`, element: <StudyBuilder /> },
        { path: `*`, element: <NotFoundPage /> },
        ]
    }, {
      path: 'auth',
      element: <DynamicFormNewPage />, children: [{ element: <IndexPage />, index: true }, ...authData]
    },]);
  }, [user])

  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      children: [{ element: <HomePage />, index: true }],
    },
    {
      path: '/test/:id',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [{ element: <TestPage />, index: true }],
    },
    // Auth routes
    // ...authRouteData,
    // ...authDemoRoutes,

    // Dashboard routes
    ...menuItem,
    // Main routes
    ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
  ]);
}
